@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

.grecaptcha-badge {
    display: none!important;
}

@layer base {
	* {
		scroll-behavior: smooth;
	}

	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	.h1 {
		@apply text-primary-700 text-3xl font-extrabold;
	}
	.h2 {
		@apply bg-text-gradient bg-clip-text text-transparent text-2xl font-extrabold mb-4;
	}
	.h3 {
		@apply text-primary-700 text-xl font-bold mb-4;
	}
	.h4 {
		@apply text-white-accent text-lg font-semibold;
	}
	.h5 {
		@apply text-white-accent text-base font-semibold;
	}
	.p {
		@apply text-sm;
	}

	button,
	a.button {
		@apply px-4 py-2 rounded-md shadow-primary border font-bold;
	}

	button.primary,
	a.button.primary {
		@apply border-primary-700 text-primary-700;
	}
	button.primary.solid,
	a.button.primary.solid {
		@apply bg-primary-700 text-white-accent;
	}


	button.secondary,
	a.button.secondary {
		@apply border-secondary-200 text-secondary-200;
	}
	button.secondary.solid,
	a.button.secondary.solid {
		@apply bg-secondary-200 text-black;
	}


	button.white,
	a.button.white {
		@apply border-white text-white-accent;
	}
	button.white.solid,
	a.button.white.solid {
		@apply bg-white text-black;
	}


	button.black,
	a.button.black {
		@apply border-black text-black;
	}
	button.white.solid,
	a.button.white.solid {
		@apply bg-black text-white-accent;
	}

	.form-message.primary {
		@apply text-primary-700;
	}
	.form-message.secondary {
		@apply text-secondary-200;
	}
	.form-message.black {
		@apply text-black;
	}
	.form-message.white {
		@apply text-white-accent;
	}

	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	textarea {
		@apply bg-gray px-4 py-2 font-medium;
	}

	.copyright a {
		@apply transition-all duration-300 opacity-75 hover:opacity-100;
	}

	.scroll-to-left {
		animation: scrollToLeft 10s linear infinite;
	}

	@keyframes scrollToLeft {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-100% - 5rem));
		}
	}

	.scroll-to-left-slow {
        animation: scrollToLeftSlow 30s infinite linear;
    }

    @keyframes scrollToLeftSlow {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-100% - 2.5rem));
        }
    }

	.up-and-down {
		animation: upAndDown 1s alternate-reverse infinite;
	}

	@keyframes upAndDown {
		0% {
			margin-top: 0;
		}
		50%, 100% {
			margin-top: 0.5rem;
		}
	}

	.fade-in {
		animation: fadeIn 0.3s ease-in-out;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.fade-out {
		animation: fadeOut 0.3s ease-in-out;	
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.move-in-from-left {
		animation: moveInFromLeft 2s ease-in-out forwards;
	}

	@keyframes moveInFromLeft {
		0% {
			transform: translateX(-175%);
		}
		100% {
			transform: translateX(0);
		}
	}

	.move-in-from-bottom {
		animation: moveInFromBottom 2s ease-in-out forwards;
	}

	@keyframes moveInFromBottom {
		0% {
			transform: translateY(10%);
		}
		100% {
			transform: translateY(0);
		}
	}

	.move-out-to-bottom {
		animation: moveOutToBottom 1.5s ease-in-out forwards;
	}

	@keyframes moveOutToBottom {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(10%);
		}
	}

	@media (min-width: 768px) {
		.trustoo-widget {
			flex-direction: row;
		}


		.h1 {
			@apply text-5xl mb-4;
		}
		.h2 {
			@apply text-4xl;
		}
		.h3 {
			@apply text-3xl;
		}
		.h4 {
			@apply text-2xl;
		}
		.h5 {
			@apply text-xl;
		}
		.p {
			@apply text-base;
		}

		@keyframes upAndDown {
			0% {
				margin-top: 0.5rem;
			}
			50%, 100% {
				margin-top: 1rem;
			}
		}
	}
}